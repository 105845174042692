import { Button, Card, Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faBed, faCalendar, faStar, faStarHalf, faUser} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment/moment'
import React from 'react'
import { getNumberOfTravelers, getPricePerPerson } from '@/utility'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import AppModal from '@/shared/components/AppModal'
import LOCATION from '@/constants/Location'
import { useAuth } from '@/services/Auth'
import LoadingButton from '@/shared/components/LoadingButton'
import { isValidHttpUrl } from '@/utility'
import { Link } from 'react-router-dom'
import LoadingCover from '../../../shared/components/LoadingCover'
import Slider from "react-slick";
import {faArrowRight, faForkKnife, faPeopleRoof} from "@fortawesome/pro-solid-svg-icons";

function HotelCard ({ hotel, deal, selectHotel, gotToHotels, fullCard, noChangeDeal, disableBook, selected }) {

  const auth = useAuth()
  const baseUrl = auth.api.defaults.baseURL
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)
  const [modalBody, setModalBody] = useState(null)
  const [confirmText, setConfirmText] = useState(null)
  const [cancelText, setCancelText] = useState(null)
  const [booking, setBooking] = useState(false)
  const [booked, setBooked] = useState(hotel?.booked)

  let images = hotel?.images?.map(image => {
    return isValidHttpUrl(image?.url) ? image?.url : `${baseUrl}/${image?.url}`
  }) ?? [];

  const slickSettings = {
    dots: false,
    arrows: images.length > 1,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const markAsBookedRequest = () => {
    if (!booked) {
      setModalTitle(t('pages.trips.notifications.book_hotel_confirm.title'))
      setModalBody(t('pages.trips.notifications.book_hotel_confirm.body'))
      setConfirmText(t('common.dialogs.actions.yes'))
      setCancelText(t('common.dialogs.actions.cancel'))
      setShowModal(true)
    } else {
      setModalTitle(t('pages.trips.notifications.remove_from_booked_confirm.title'))
      setModalBody(t('pages.trips.notifications.remove_from_booked_confirm.body'))
      setConfirmText(t('common.dialogs.actions.yes'))
      setCancelText(t('common.dialogs.actions.cancel'))
      setShowModal(true)
    }
  }

  const handleConfirm = () => {
    setShowModal(false)
    bookHotel()
  }

  const handleCancel = () => {
    setShowModal(false)
  }

  const bookHotel = () => {

    setBooking(true)
    auth.postRequest(`${LOCATION.HOTELS.API.path}/${hotel.id}/book`)
      .then(response => {
        setBooked(!booked)
        setBooking(false)
      })
      .catch(error => {
        setBooking(false)
      })
  }

  if (!deal) return <LoadingCover/>

  return (
    <>
      <AppModal
        show={showModal}
        title={modalTitle}
        body={modalBody}
        confirmText={confirmText}
        cancelText={cancelText}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
      <Card className="h-100 card-layout">
	  	<div className="float-left w-100 block image-slider">
        <Slider {...slickSettings}>
          {images.map((img, index) => (
              <div>
                <img key={index} src={img} className="deal-card" alt={deal.location.destination} />
              </div>
          ))}
        </Slider>
		</div>
        <Card.Body>
          <Row className="d-flex flex-column h-100">
            <Col>

				<div className="float-left w-100 location-top">
					 <h5 className="heading-text text-primary">{hotel.name}</h5>
					<div className="float-left w-100 flex justify-content-start align-items-end">
                      <div className="pricing">
                        <span>From</span> <strong>{deal?.currency_symbol}{getPricePerPerson(hotel.price, deal)} pp</strong> <small>({deal?.currency_symbol}{hotel.price})</small>
                      </div>
                    </div>
				</div>
            </Col>
            <Col className="mb-3">
              <div className="d-flex justify-content-between bg-secondary review-section">
                <div>
                  <strong>Review Score</strong>
                  <div className="d-flex rating">
                    <b className={`ms-2 p-0 alert ${hotel?.guest_rating * 2 > 4 ? (hotel?.guest_rating * 2 >= 8 ? 'bg-success text-white' : 'bg-secondary text-white') : 'bg-danger text-white'}`}>
                      {hotel?.guest_rating * 2}
                    </b>
                    {
                      hotel?.guest_rating * 2 > 4 ? (hotel?.guest_rating * 2 >= 8 ? 'Excellent' : 'Good') : 'Poor'
                    }
                  </div>
                </div>

                <div>
                  <strong>
                    Star Rating
                  </strong>
                  <div className="d-flex stars">
                    {
                      Array.from({ length: Math.floor(hotel?.start_rating) }, (_, index) => (
                          <FontAwesomeIcon key={index} icon={faStar} className="text-warning"/>
                      ))
                    }
                    {
                        hotel?.start_rating % 1 > 0 &&
                        <FontAwesomeIcon icon={faStarHalf} className="text-warning"/>
                    }
                    {
                        hotel?.start_rating == 0 &&
                        <span className="text-warning">No Rating</span>
                    }
                  </div>
                </div>
              </div>
            </Col>
            <Col>



              <ul className="list-group list-group-flush benefits-list">
                <li className="list-group-item  ps-0">
                  <FontAwesomeIcon className="me-2 text-primary" icon={faUser}/>
                  {t('pages.deals.accommodation_for', { count: getNumberOfTravelers(deal) })}
                </li>
                <li className="list-group-item  ps-0">
                  <FontAwesomeIcon className="me-2 text-primary" icon={faCalendar}/>
                  {moment(deal?.from).format(t('common.formats.short_date'))} - {moment(deal?.to).format(t('common.formats.short_date'))}
                </li>
                <li className="list-group-item  ps-0">
                  <FontAwesomeIcon className="me-2 text-primary" icon={faBed}/>
                  {hotel.room_description}
                </li>
                {
                    hotel?.property_type?.toLowerCase().includes('apart') && hotel.room_max_occupancy > 0 && (
                      <li className="list-group-item  ps-0">
                        <FontAwesomeIcon className="me-2 text-primary" icon={faPeopleRoof}/>
                        <strong>Max Occupancy:</strong> {hotel.room_max_occupancy}
                      </li>
                    )
                }
                {/*{*/}
                {/*  hotel?.property_type?.toLowerCase().includes('apart') && (*/}
                {/*        <li className="list-group-item  ps-0">*/}
                {/*          <small className="text-danger">*/}
                {/*            The occupancy of apartments may vary, please ensure you check the apartment is an appropriate*/}
                {/*            size for your group once you are on the booking page*/}
                {/*          </small>*/}
                {/*        </li>*/}
                {/*    )*/}
                {/*}*/}
                {
                    hotel?.hotel_amenities && hotel?.hotel_amenities.includes('Free breakfast') &&
                    (
                        <li className="list-group-item  ps-0">
                          <FontAwesomeIcon size="sm" className="text-primary" icon={faForkKnife}/>
                          Free Breakfast
                        </li>
                  )
                }
              </ul>

            </Col>
            <Col className="hotels mt-auto">
              <div className="">
                {
                  disableBook ?

                    <a className="btn btn-primary heading-text" href={hotel.link} target="_blank" rel="noreferrer">
                      {t('pages.deals.buttons.more_information')}
                    </a>
                    :

                    !hotel.expired ?
                      <a className="btn btn-primary heading-text" href={hotel.link} target="_blank" rel="noreferrer">
                        <div className="d-flex justify-content-between align-items-center">
                          {t('pages.deals.buttons.book_now')}
                          <FontAwesomeIcon icon={faArrowRight}/>
                        </div>
                      </a>
                      : null
                }
                {
                  fullCard ?
                    <Button disabled={selected} className="heading-text text-white" onClick={() => selectHotel(hotel)}>
                      {t('pages.deals.buttons.select_hotel')}
                    </Button>
                    :
                    <>
                      {
                        auth.user?.client &&
                        <>
                          <hr/>
                          {
                            !noChangeDeal &&
                            <Link to={`${LOCATION.DEALS.LIST.path}/${deal?.id}/hotels`}
                                  className="btn btn-dark text-white heading-text">
                              {t('pages.deals.buttons.choose_hotel_again')}
                            </Link>
                          }

                          {
                            hotel.expired ?
                              <>
                                <Button variant="danger" disabled={true}
                                        className="text-white heading-text">{t('pages.trips.buttons.expired')}</Button>
                              </>
                              :
                              hotel.sold_out ?
                                <>
                                  <Button variant="danger" disabled={true}
                                          className="text-white heading-text">{t('pages.trips.buttons.sold_out')}</Button>
                                </>
                                :
                                null
                          }
                          <LoadingButton
                            loading={booking}
                            type="button"
                            variant="outline-dark"
                            className="heading-text"
                            onSubmit={markAsBookedRequest}
                            titleTranslationKey={`${booked ? 'pages.trips.buttons.booked' : 'pages.deals.buttons.mark_hotel_as_booked'}`}
                          />

                        </>
                      }
                    </>
                }
              </div>
            </Col>

          </Row>
        </Card.Body>
      </Card>
    </>
  )
}

export default HotelCard