import React, { useEffect, useState } from 'react'
import LoadingCover from '@/shared/components/LoadingCover'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { getNumberOfTravelers, getPricePerPerson, isValidHttpUrl } from '@/utility'
import { useTranslation } from 'react-i18next'
import { useAuth } from '@/services/Auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBed, faCalendar, faLocationPin, faUserGroup, faStar, faStarHalf, faMinus, faPlus, faPlane, faPlaneDeparture, faH } from '@fortawesome/free-solid-svg-icons'
import {faBedFront, faArrowRight, faForkKnife, faPeopleRoof} from '@fortawesome/pro-solid-svg-icons';
import moment from 'moment/moment'
import FlightLeg from './FlightLeg'
import { Link } from 'react-router-dom'
import LOCATION from '@/constants/Location'
import LoadingButton from '@/shared/components/LoadingButton'
import AppModal from '@/shared/components/AppModal'
import DealCustomMessageForm from '../../Admin/Top15Deals/components/DealCustomMessageForm'
import Slider from "react-slick";

function FullDealCard ({
  deal,
  dateOption,
  selectFlight,
  selected,
  hideDescription,
  linkToDeal,
  linkToEditDeal,
  shortFullCard,
  removeDeal,
  preserveDeal,
  selectDeal,
  manageCustomMessage,
  showCustomMessage,
  defaultImage,
  specialText
}) {
  const { t } = useTranslation()
  const auth = useAuth()
  const baseUrl = auth.api.defaults.baseURL

  const [flight, setFlight] = useState(null)
  const [hotel, setHotel] = useState(null)

  const [updatingPrice, setUpdatingPrice] = useState(false)

  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)
  const [modalBody, setModalBody] = useState(null)
  const [confirmText, setConfirmText] = useState(null)
  const [coloredText, segColoredText] = useState(null)
  const [cancelText, setCancelText] = useState(t('common.buttons.cancel'))

  let images = deal.hotel?.images?.map(image => {
    return isValidHttpUrl(image?.url) ? image?.url : `${baseUrl}/${image?.url}`
  }) ?? [];

  const slickSettings = {
    dots: false,
    arrows: images.length > 1,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  useEffect(() => {
    if (deal && deal?.hotel) {
      setHotel(deal.hotel)
    }
    if (deal && deal?.flight) {
      setFlight(deal.flight)
    }
  }, [deal])

  const confirmLivePrice = () => {
    setUpdatingPrice(true)

    auth.getRequest(`${LOCATION.FLIGHTS.API.path}/${deal.flight_id}/live/${deal.id}`)
      .then(response => {
        if (response.data === 'updated') {
          setModalTitle(t('pages.deals.notifications.live_price_updated.title'))
          setModalBody(t('pages.deals.notifications.live_price_updated.body'))
        } else if (response.data === 'updated_not_direct') {
          setModalTitle(t('pages.deals.notifications.live_price_updated.title'))
          setModalBody(t('pages.deals.notifications.live_price_updated.body'))
          segColoredText(t('pages.deals.notifications.live_price_updated.no_direct_flight_found', { destination: deal.location.destination }))
        } else {
          setModalTitle(t('pages.deals.notifications.live_price_no_found.title'))
          setModalBody(t('pages.deals.notifications.live_price_no_found.body'))
          setUpdatingPrice(false)
        }
        setConfirmText(t('common.dialogs.actions.okay'))
        setCancelText(null)
        setShowModal(true)
      })
      .catch(error => {
        setUpdatingPrice(false)
      })
  }

  const handleConfirm = () => {
    setShowModal(false)
    if (updatingPrice) {
      setUpdatingPrice(false)
      window.location.reload()
    }
  }

  const handleCancel = () => {
    setShowModal(false)
    setUpdatingPrice(false)
  }

  if (!flight) return <LoadingCover/>

  return (
    <>
      <AppModal
        show={showModal}
        title={modalTitle}
        body={modalBody}
        coloredText={coloredText}
        confirmText={confirmText}
        cancelText={cancelText}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
      />
      <Card className={`card-layout h-100 ${selected ? 'selected' : ''}`}>
	  	<div className="float-left w-100 block image-slider">
        {!shortFullCard && (
            <Slider {...slickSettings}>
                {images.map((img, index) => (
                    <div>
                      <img key={index} src={img} className="deal-card" alt={deal.location.destination} />
                    </div>
                ))}
            </Slider>
        )}
		</div>
        <Card.Body>
          <Row className="d-flex flex-column h-100 justify-content-start align-items-start">
            <Col>
			<div className="float-left w-full location-top">
              <h5 className="heading-text text-primary">
                <FontAwesomeIcon icon={faLocationPin} />
                {
                  deal.location?.page ?
                    <a target="_blank" href={`${deal.location.page}`}>{deal.location.destination}</a>
                    :
                    deal.location.destination
                }
              </h5>
			 
			 <div className="float-left w-100 flex justify-content-start align-items-end">
			 	  {
                !dateOption ?
                    <div className="pricing">
                      <span>From</span> <strong>{deal.currency_symbol}{getPricePerPerson(deal.total_amount, deal)} pp</strong> <small>({deal.currency_symbol}{deal.total_amount})</small>
                    </div>
                    :
                    <div className="pricing">
                      <span>{t('common.flights')}&nbsp;-&nbsp;</span>
                      From <strong>{flight.currency_symbol}{flight.price}</strong> <small>({flight.currency_symbol}{getPricePerPerson(flight.price, deal)} pp)</small>
                    </div>
              }
			  </div>
              {
                specialText && specialText.length > 0 && <p dangerouslySetInnerHTML={{ __html: specialText }} />
              }
			 </div>
            </Col>

            <Col>
              <div className="d-flex justify-content-between bg-secondary review-section">
                <div>
                  <strong>Review Score</strong>
                  <div className="d-flex rating">
                    <b className={`ms-2 p-0 alert ${deal.hotel?.guest_rating * 2 > 4 ? (deal.hotel?.guest_rating * 2 >= 8 ? 'bg-success text-white' : 'bg-secondary text-white') : 'bg-danger text-white'}`}>
                      {deal.hotel?.guest_rating * 2}
                    </b>
                    {
                      deal.hotel?.guest_rating * 2 > 4 ? (deal.hotel?.guest_rating * 2 >= 8 ? 'Excellent' : 'Good') : 'Poor'
                    }
                  </div>
                </div>

                <div>
					<strong>
                  Star Rating
					</strong>
                  <div className="d-flex stars">
                    {
                        Array.from({ length: Math.floor(deal.hotel?.start_rating) }, (_, index) => (
                            <FontAwesomeIcon key={index} icon={faStar} className="text-warning"/>
                        ))
                    }
                    {
                      deal.hotel?.start_rating % 1 > 0 &&
                      <FontAwesomeIcon icon={faStarHalf} className="text-warning"/>
                    }
                    {
                      deal.hotel?.start_rating == 0 &&
                        <span className="text-warning">No Rating</span>
                    }
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <City
                shortFullCard={shortFullCard}
                deal={deal}
                flight={flight}
                dateOption={dateOption}
                hideDescription={hideDescription}
                showCustomMessage={showCustomMessage}
              />

            </Col>
            <Col className="flights mt-auto">

              <Flight
                deal={deal}
                shortFullCard={shortFullCard}
                flight={flight}
                dateOption={dateOption}
                selectFlight={selectFlight}
                selected={selected}
                linkToDeal={linkToDeal}
                confirmLivePrice={confirmLivePrice}
                updatingPrice={updatingPrice}
              />
            </Col>

            {
              hotel &&
              <Col className="hotels">
                
                <Hotel
                  deal={deal}
                  shortFullCard={shortFullCard}
                  hotel={hotel}
                  linkToDeal={linkToDeal}
                />
              </Col>
            }

            {
              (manageCustomMessage && auth.user.admin) &&
              <DealCustomMessageForm deal={deal}/>
            }

            {
              linkToDeal ?
                <Col className="col">
                  
                    <Link to={`${LOCATION.DEALS.LIST.path}/${deal.id}`}
                          className="btn btn-primary text-white heading-text">
                      <div className="d-flex justify-content-between align-items-center">
                        {t('pages.deals.buttons.view_this_deal')}
                        <FontAwesomeIcon icon={faArrowRight}/>
                      </div>
                    </Link>
                  
                </Col>
                :
                null
            }
            {
              linkToEditDeal ?
                <Col className="col">
                  
                    <Link to={`${LOCATION.DEALS.LIST.path}/${deal.id}/edit`}
                          className="btn btn-primary text-white heading-text">
                      {t('pages.deals.buttons.edit_this_deal')}
                    </Link>
                
                </Col>
                :
                null
            }
            {
              removeDeal ?
                <Col className="col">
                  
                    <Button variant="danger" onClick={() => removeDeal(deal)}
                            className="text-white heading-text">{t('common.buttons.remove')}</Button>
                  
                </Col>
                :
                null
            }
            {
              preserveDeal ?
                  <Col className="col-auto">
                    {
                      deal.preserve ?
                          (

                                <Button variant="secondary" onClick={() => preserveDeal(deal)}
                                        className="text-white heading-text">{t('common.buttons.unpreserve')}</Button>

                          )
                          :
                          (

                                <Button variant="secondary" onClick={() => preserveDeal(deal)}
                                        className="text-white heading-text">{t('common.buttons.preserve')}</Button>

                          )
                    }
                    < /Col>
                :
                null
              }
            {
              selectDeal ?
                  <Col className="col-auto">
                    {
                      deal.selected ?
                          (

                                <Button variant="secondary" onClick={() => selectDeal(deal)}
                                        className="text-white heading-text">{t('common.buttons.unselect')}</Button>

                          )
                          :
                          (

                                <Button variant="secondary" onClick={() => selectDeal(deal)}
                                        className="text-white heading-text">{t('common.buttons.select')}</Button>

                          )
                    }
                    < /Col>
                :
                null
              }
                  </Row>
              </Card.Body>
              </Card>
              </>
              )
            }

            function Hotel ({deal, hotel, linkToDeal, shortFullCard}) {
            const {t} = useTranslation()

            return (
    <>

      <h6 className="">
        {t('common.hotel')}&nbsp;-&nbsp;
        From <strong>{deal.currency_symbol}{getPricePerPerson(hotel.price, deal)} pp</strong> <small>({deal.currency_symbol}{hotel.price})</small>
      </h6>

      <Row>
        <Col md={6} className="travel-meta features">
          <div className="d-flex align-items-center">
            <FontAwesomeIcon className="text-primary" icon={faH} className="me-2 bg-primary text-white p-1 rounded"/>
            <span className="">{hotel.name}</span>
          </div>
        </Col>
        <Col md={6}  className="travel-meta  features">
          {!shortFullCard &&
              <div className="d-flex align-items-center ml-auto">
                <FontAwesomeIcon className="text-primary" icon={faBedFront}/>
                <span className="">{hotel.room_description}</span>
              </div>
          }
        </Col>
        {
          hotel?.property_type?.toLowerCase().includes('apart') && hotel.room_max_occupancy > 0 && (
                <Col md={6}  className="travel-meta  features">
                  {!shortFullCard &&
                      <div className="d-flex align-items-center ml-auto">
                        <FontAwesomeIcon className="text-primary" icon={faPeopleRoof}/>
                        <span className=""><strong>Max Occupancy:</strong> {hotel.room_max_occupancy}</span>
                      </div>
                  }
                </Col>
            )
        }
      </Row>
      {/*{*/}
      {/*  hotel?.property_type?.toLowerCase().includes('apart') && (*/}
      {/*      <Row>*/}
      {/*        <Col md={12}>*/}
      {/*          <small className="text-danger">*/}
      {/*            The occupancy of apartments may vary, please ensure you check the apartment is an appropriate size for your group once you are on the booking page*/}
      {/*          </small>*/}
      {/*        </Col>*/}
      {/*      </Row>*/}
      {/*  )*/}
      {/*}*/}

      {
        !linkToDeal && !shortFullCard ?
          hotel.sold_out ?
            <>
              <Button variant="danger" disabled={true}
                      className="text-white heading-text">{t('pages.trips.buttons.sold_out')}</Button>
            </>
            :
           
              <a className="btn btn-primary heading-text" href={hotel.link} target="_blank" rel="noreferrer">
                <div className="d-flex justify-content-between align-items-center">
                {t('pages.deals.buttons.book_accommodation_now')}

                <FontAwesomeIcon icon={faArrowRight}/>
                </div>
              </a>
            
          : null
      }
    </>
  )
}

function Flight ({
  deal,
  flight,
  dateOption,
  selectFlight,
  selected,
  linkToDeal,
  confirmLivePrice,
  updatingPrice,
  shortFullCard
}) {
  const { t } = useTranslation()
  return (
    <>
      {
        !dateOption &&
        <h6 className="">
          {t('common.flights')}&nbsp;-&nbsp;
          From <strong>{flight.currency_symbol}{getPricePerPerson(flight.price, deal)} pp</strong> <small>({flight.currency_symbol}{flight.price})</small>
        </h6>
      }
      <Row>
        <Col xs={6} sm={6} md={6}>
          <FlightLeg
              deal={deal}
              className="mb-2"
              shortCard={true}
              flight={flight}
              outBoundFlight={flight.outbound_flight}
              inBoundFlight={flight.inbound_flight}
              split={true}
          />
        </Col>
        <Col xs={6} sm={6} md={6}>
          <FlightLeg
              deal={deal}
              shortCard={true}
              flight={flight}
              outBoundFlight={flight.inbound_flight}
              inBoundFlight={flight.outbound_flight}
              split={true}
          />
        </Col>
      </Row>
      <div className="d-flex column-buttons">
        {
          !linkToDeal ?
            dateOption ?
              <Button
                disabled={selected}
                variant={'dark'}
                className="text-white heading-text "
                onClick={() => selectFlight()}
              >
                {t('pages.deals.buttons.choose_flight_date')}
              </Button>
              :

              deal.custom_deal || flight.live_price && moment().diff(moment(flight?.created_at), 'days') < 1 ?
                !shortFullCard ?
                  flight.sold_out ?
                    <>
                      <Button variant="danger" disabled={true}
                              className="text-white heading-text">{t('pages.trips.buttons.sold_out')}</Button>
                    </>
                    :
                    <a className="btn btn-primary heading-text" href={flight.link} target="_blank"
                       rel="noreferrer">
                      <div className="d-flex justify-content-between align-items-center">
                        {t('pages.deals.buttons.book_flights_now')}
                        <FontAwesomeIcon icon={faPlaneDeparture}/>
                      </div>
                    </a>
                  : null
                :
                <LoadingButton
                  variant="outline-secondary"
                  onSubmit={confirmLivePrice}
                  loading={updatingPrice}
                  titleTranslationKey={t('pages.deals.buttons.conform_price')}
                  className="heading-text"
                  buttonTextElement={
                    <div className="d-flex justify-content-between align-items-center">
                      {t('pages.deals.buttons.conform_price')}
                      <FontAwesomeIcon icon={faArrowRight}/>
                    </div>}
                />
            :
            null
        }
      </div>

    </>
  )
}

function City ({ deal, flight, dateOption, hideDescription, showCustomMessage }) {
  const { t } = useTranslation();
  const [showDescription, setShowDescription] = useState(false);

  return (
    <>
      <div className="d-flex travel-meta row">
        <div className="d-flex align-items-center col">
          <FontAwesomeIcon size="sm" className="text-primary" icon={faCalendar}/>
          {moment(deal?.date).format(t('common.formats.date_with_day'))} - {moment(deal.flight.inbound_flight.departure_time).format(t('common.formats.date_with_day'))}
        </div>
        <div className="d-flex align-items-center col flex-0">
          <FontAwesomeIcon size="sm" className="text-primary" icon={faUserGroup}/>
          {getNumberOfTravelers(deal)}
        </div>
      </div>
      {
          deal?.hotel?.hotel_amenities && deal?.hotel?.hotel_amenities.includes('Free breakfast') &&
          (
              <div className="d-flex  travel-meta breakfast row">
                <div className="d-flex align-items-center col-12">
                  <FontAwesomeIcon size="sm" className="text-primary" icon={faForkKnife}/>
                  Free Breakfast
                </div>
              </div>

          )
      }

      {
        (!dateOption && !hideDescription) &&
          <div className="description">
            <p>
              {deal?.location?.description.length > 80 && !showDescription ? deal?.location?.description.slice(0, 80) + '...' : deal?.location?.description}
            </p>
            <Button className="btn-description-toggle" variant="link" onClick={() => setShowDescription(!showDescription)}>
              <FontAwesomeIcon icon={showDescription ? faMinus : faPlus}/>
            </Button>
          </div>
      }

      {
        showCustomMessage && deal.custom_message &&
        <div className="testimonial">
          
          <p className="fst-italic">
            "{deal?.custom_message}"
          </p>
          <div className="d-flex align-items-center justify-content-start testimonial-meta">
            <img className="custom-message-logo" src="/assets/images/jenna.png"/>
            <span className="">Jen, The Travel Mum</span>
          </div>
        </div>
      }
    </>
  )
}

export default FullDealCard